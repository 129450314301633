/*=================
     
    column.scss

=======================*/

.column_ranking_block {
  padding: 30px 30px 15px;
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin: 0 0 50px;
}

@media only screen and (max-width: 960px) {
  .column_ranking_block {
    padding: 20px 12px;
    margin: 0 0 30px;
  }
}

.column_ranking_title {
  font-size: 45px;
  line-height: 1.5;
  margin: 0;
  color: #242424;

  &:after {
    content: "";
    display: block;
    width: 80px;
    height: 4px;
    background: #00ECBC;
    margin: 10px 0 30px;
  }

  span {
    font-size: 20px;
    margin: 0 0 0 10px;
    font-weight: bold;
    letter-spacing: 1.2px;
  }
}

@media only screen and (max-width: 960px) {
  .column_ranking_title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 960px) {
  .column_ranking_title:after {
    width: 50px;
    height: 3px;
  }
}

@media only screen and (max-width: 960px) {
  .column_ranking_title span {
    font-size: 14px;
  }
}

.column_layout {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0 0 100px;

  .column_main_block {
    width: 70%;
  }

  .comlumn_aside {
    background: #F9F9F9;
    padding: 30px 20px;
    width: calc( 30% - 30px );
  }
}

@media only screen and (max-width: 960px) {
  .column_layout {
    display: block;
    padding: 0 0 60px;
  }
}

@media only screen and (max-width: 960px) {
  .column_layout .column_main_block {
    width: 100%;
    margin: 0 0 50px;
  }
}

@media only screen and (max-width: 960px) {
  .column_layout .comlumn_aside {
    width: 100%;
    padding: 20px 12px;
  }
}

.column_info_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 0 20px;

  .column_info_num {
    font-size: 14px;
    color: #242424;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1.2px;

    span {
      color: #007ADF;
    }
  }
}

@media only screen and (max-width: 640px) {
  .column_info_block .column_info_num {
    font-size: 12px;
  }
}

.column_info_select {
  display: block;
  width: 100%;
  max-width: 100px;
  height: 35px;
  outline: none;
  border: none;
  border-bottom: 1px #D4D4D4 solid;
  font-size: 14px;
  color: #333;
  letter-spacing: 1.2px;
  background: url("/images/common/icon08.svg") right center no-repeat #fff;
  background-size: 10px;
  padding: 0 20px 0 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

@media only screen and (max-width: 640px) {
  .column_info_select {
    font-size: 12px;
  }
}

.column_list {
  margin: 0 0 60px;

  .column_item {
    margin: 0 0 40px;
  }

  .column_link {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-decoration: none;
  }

  .column_pic {
    position: relative;
    display: block;
    width: 250px;
    min-width: 250px;
    height: 250px;
    margin: 0 30px 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 20px;

    &:after {
      content: "";
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }

    .column_date {
      position: relative;
      z-index: 2;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1.5px;
      color: #fff;
    }
  }

  .column_info {
    width: 100%;
  }

  .column_title {
    font-size: 24px;
    color: #242424;
    font-weight: 700;
    line-height: 1.6;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin: 0 0 20px;
  }

  .column_text {
    font-size: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    line-height: 1.6;
    color: #242424;
  }
}

@media only screen and (max-width: 500px) {
  .column_list .column_link {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .column_list .column_pic {
    width: 200px;
    min-width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 500px) {
  .column_list .column_pic {
    width: 100%;
    min-width: inherit;
    height: 180px;
    margin: 0 0 12px;
  }
}

@media only screen and (max-width: 960px) {
  .column_list .column_pic .column_date {
    font-size: 14px;
  }
}

@media only screen and (max-width: 960px) {
  .column_list .column_title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 960px) {
  .column_list .column_text {
    font-size: 12px;
  }
}

.column_category_block {
  margin: 0 0 30px;
}

.column_aside_title {
  font-size: 18px;
  color: #007ADF;
  font-weight: 900;
  letter-spacing: 1.2px;
  line-height: 1.5;
  margin: 0 0 20px;
}

@media only screen and (max-width: 960px) {
  .column_aside_title {
    font-size: 16px;
  }
}

.column_category_list {
  list-style: none;
  margin: 0;
  padding: 0;

  .column_category_item {
    padding: 0 0 12px;
    margin: 0 0 12px;
    border-bottom: 1px #dcdcdc solid;

    &:last-of-type {
      border-bottom: none;
      padding: 0;
      margin: 0;
    }
  }

  .column_category_link {
    font-size: 14px;
    color: #535353;
    letter-spacing: 1.2px;
    line-height: 1.5;
    font-weight: 500;
    text-decoration: none;

    span {
      display: inline-block;
      font-size: 12px;
      color: #007ADF;
      margin: 0 0 0 5px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .column_category_list .column_category_link {
    font-size: 12px;
  }
}

.column_keyword_block {
  background: #fff;
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding: 20px;
}

@media only screen and (max-width: 960px) {
  .column_keyword_block {
    padding: 10px;
  }
}

.column_keyword_list {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  .column_keyword_item {
    margin: 0 10px 10px 0;
  }

  .column_keyword_link {
    font-size: 12px;
    color: #535353;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1.2px;
    text-decoration: none;

    &:before {
      content: "#";
      margin: 0 2px 0 0;
      color: #00ECBC;
    }
  }
}

/* detail */

.column_d_top_block {
  padding: 0 0 20px;
  margin: 0 0 20px;
  border-bottom: 1px #d4d4d4 solid;
}

.column_d_title {
  font-size: 30px;
  line-height: 1.5;
  color: #242424;
  font-weight: 900;
  margin: 0 0 10px;
}

@media only screen and (max-width: 960px) {
  .column_d_title {
    font-size: 22px;
  }
}

.column_d_editor_block {
  font-size: 14px;
  line-height: 1.5;
  color: #242424;
  margin: 0 0 50px;

  h2 {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    padding: 0 0 5px;
    line-height: 1.5;
    border-bottom: 2px #007ADF solid;
    margin: 0 0 30px;
  }

  h3 {
    font-size: 20px;
    color: #007ADF;
    font-weight: bold;
    line-height: 1.5;
    margin: 0 0 40px;
  }

  h4 {
    font-size: 16px;
    color: #535353;
    display: inline-block;
    padding: 0 0 5px;
    font-weight: bold;
    margin: 0 0 30px;
  }

  p {
    margin: 0 0 20px;
  }

  ol{
    list-style: decimal;
    margin: 0 0 20px;
  }

  ul{
    list-style: disc;
    margin: 0 0 20px;
  }

  strong{
    font-weight: bold;
  }

  img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 40px 0;
  }

  table, th, td {
    border: 1px solid black;
  }

  th, td {
    padding: 15px;
    text-align: left;
  }
}

@media only screen and (max-width: 960px) {
  .column_d_editor_block {
    font-size: 12px;
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 960px) {
  .column_d_editor_block h2 {
    font-size: 18px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 960px) {
  .column_d_editor_block h3 {
    font-size: 16px;
    margin: 0 0 20px;
    padding: 0 0 0 10px;
  }
}

@media only screen and (max-width: 960px) {
  .column_d_editor_block h4 {
    font-size: 14px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 960px) {
  .column_d_editor_block p {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 960px) {
  .column_d_editor_block img {
    margin: 20px 0;
  }
}

.column_d_sns_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin: 0 0 40px;

  .column_d_fb_btn {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    text-decoration: none;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwZWNiYyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background: -moz-linear-gradient(0deg, #007adf, #00ecbc);
    background: -webkit-linear-gradient(0deg, #007adf, #00ecbc);
    background: linear-gradient(90deg, #007adf, #00ecbc);
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;

    img {
      display: block;
      width: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .column_d_twitter_btn {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 0 0 20px;
    text-decoration: none;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwZWNiYyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background: -moz-linear-gradient(0deg, #007adf, #00ecbc);
    background: -webkit-linear-gradient(0deg, #007adf, #00ecbc);
    background: linear-gradient(90deg, #007adf, #00ecbc);
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;

    img {
      display: block;
      width: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

@media only screen and (max-width: 960px) {
  .column_d_sns_block {
    margin: 0 0 30px;
  }
}

.column_d_new_block {
  padding: 50px 0 100px;
  border-top: 1px #d4d4d4 solid;
}

@media only screen and (max-width: 960px) {
  .column_d_new_block {
    padding: 30px 0 60px;
  }
}
