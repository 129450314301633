@import 'common.scss';
@import 'top.scss';
@import 'login.scss';
@import 'register.scss';
@import 'custom_select.scss';
@import 'mypage.scss';
@import 'mailbox.scss';
@import 'contact.scss';
@import 'profile.scss';
@import 'column.scss';
@import 'mouse_pointer.scss';
@import 'document.scss';

@import 'react_select_debeers.scss';
@import 'message.scss';
/*=================
     
      scouts.scss

=======================*/

.scouts_mv_block {
  height: 100vh;
  min-height: 760px;
  width: 100%;
  padding: 0 12px;
  position: relative;
  background: url("/images/scouts/bg01.jpg") center center no-repeat;
  background-size: cover;

  .scouts_mv_inner {
    max-width: 1140px;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 55%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .scouts_mv_subtitle {
    font-size: 240px;
    color: #007ADF;
    letter-spacing: -1px;
    line-height: 220px;
    margin: 0 0 50px;
  }

  .scouts_mv_title {
    display: inline-block;
    font-size: 40px;
    letter-spacing: 5px;
    font-weight: 900;
    line-height: 1.5;
    padding: 0 0 12px;
    border-bottom: 5px #007ADF solid;
    color: #007ADF;
  }
}

@media only screen and (max-width: 840px) {
  .scouts_mv_block {
    min-height: inherit;
  }
}

@media only screen and (max-width: 1024px) {
  .scouts_mv_block .scouts_mv_subtitle {
    font-size: 180px;
    line-height: 160px;
  }
}

@media only screen and (max-width: 840px) {
  .scouts_mv_block .scouts_mv_subtitle {
    font-size: 100px;
    line-height: 90px;
  }
}

@media only screen and (max-width: 640px) {
  .scouts_mv_block .scouts_mv_subtitle {
    font-size: 70px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 1024px) {
  .scouts_mv_block .scouts_mv_title {
    font-size: 25px;
  }
}

@media only screen and (max-width: 840px) {
  .scouts_mv_block .scouts_mv_title {
    letter-spacing: 3px;
    font-size: 18px;
    padding: 0 0 8px;
    border-bottom: 3px #007ADF solid;
  }
}

@media only screen and (max-width: 640px) {
  .scouts_mv_block .scouts_mv_title {
    font-size: 16px;
  }
}

.scouts_about_block {
  height: 1080px;
  width: 100%;
  position: relative;
  background: url("/images/scouts/bg02.jpg") center center no-repeat;
  background-size: cover;
  padding: 0 8px;

  .scouts_about_inner {
    display: block;
    background: rgba(255, 255, 255, 0.95);
    max-width: 900px;
    width: 100%;
    height: 100%;
    padding: 360px 0 0;
    position: absolute;
    top: 0;
    left: 50%;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
  }

  .scouts_about_pic {
    position: absolute;
    z-index: 1;
    width: 1013px;
    top: 20px;
    left: 50%;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .scouts_about_title {
    font-size: 40px;
    letter-spacing: 1.5px;
    color: #242424;
    font-weight: 900;

    &:after {
      content: "";
      display: block;
      width: 80px;
      height: 5px;
      background: #242424;
      margin: 50px auto 60px;
    }
  }

  .scouts_about_text {
    font-size: 16px;
    color: #242424;
    line-height: 2;
    font-weight: 500;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_about_block {
    height: inherit;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_about_block .scouts_about_inner {
    margin: 0 auto;
    height: inherit;
    padding: 100px 8px;
    position: relative;
    top: inherit;
    left: inherit;
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1200px) {
  .scouts_about_block .scouts_about_pic {
    width: 900px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_about_block .scouts_about_pic {
    max-width: 900px;
    width: 90%;
    top: 10px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_about_block .scouts_about_title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_about_block .scouts_about_title:after {
    margin: 25px auto 30px;
    height: 3px;
    width: 60px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_about_block .scouts_about_text {
    font-size: 12px;
  }
}

.scouts_cv_block {
  text-align: center;
  padding: 50px 0 65px;
  color: #fff;
  text-align: center;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwZWNiYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -moz-linear-gradient(0deg, #00ecbc, #007adf);
  background: -webkit-linear-gradient(0deg, #00ecbc, #007adf);
  background: linear-gradient(90deg, #00ecbc, #007adf);

  .scouts_cv_title {
    font-size: 28px;
    letter-spacing: 1.2px;
    font-weight: bold;
    line-height: 1.5;
    margin: 0 0 20px;
  }

  .scouts_cv_tel {
    font-size: 48px;
    line-height: 1.5;
    font-weight: 900;
    margin: 0 0 20px;

    img {
      display: inline-block;
      width: 30px;
      margin: 0 10px 0 0;
    }
  }

  .scouts_cv_time {
    font-size: 20px;
    line-height: 1.5;
    margin: 0 0 50px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_cv_block {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_cv_block .scouts_cv_title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_cv_block .scouts_cv_tel {
    font-size: 30px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_cv_block .scouts_cv_tel img {
    width: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_cv_block .scouts_cv_time {
    font-size: 14px;
    margin: 0 0 30px;
  }
}

.scouts_cv_btn {
  position: relative;
  display: block;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  line-height: 80px;
  height: 80px;
  background: #fff;
  color: #007ADF;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;

  img {
    display: block;
    width: 14px;
    position: absolute;
    top: 50%;
    right: 30px;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 960px) {
  .scouts_cv_btn {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_cv_btn img {
    right: 15px;
  }
}

.scouts_common_title {
  font-size: 40px;
  line-height: 1.5;
  letter-spacing: 1.2px;
  font-weight: 900;
  text-align: center;
  color: #007ADF;
}

@media only screen and (max-width: 960px) {
  .scouts_common_title {
    font-size: 22px;
  }
}

.scouts_resolve_block {
  padding: 150px 0;
}

@media only screen and (max-width: 960px) {
  .scouts_resolve_block {
    padding: 60px 0;
  }
}

.scouts_resolve_list {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;

  .scouts_resolve_item {
    margin: 0 40px 0 0;

    &:last-of-type {
      margin: 0;
    }

    img {
      max-width: 100%;
      width: auto;
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 640px) {
  .scouts_resolve_list {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .scouts_resolve_list .scouts_resolve_item {
    margin: 0 0 50px;
    text-align: center;
  }
}

.scouts_flow_block {
  padding: 150px 0;

  .scouts_flow_pic_pc {
    display: block;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
  }

  .scouts_flow_pic_sp {
    display: none;
    max-width: 288px;
    width: 100%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_flow_block {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 750px) {
  .scouts_flow_block .scouts_flow_pic_pc {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .scouts_flow_block .scouts_flow_pic_sp {
    display: block;
  }
}

.scouts_price_block {
  padding: 76px 0 50px;
  text-align: center;
  background: #F9F9F9;

  .scouts_price_title {
    display: inline-block;
    font-size: 40px;
    line-height: 1.2;
    margin: 0 0 30px;
    color: #242424;
    font-weight: 900;
    letter-spacing: 1.2px;
    background: linear-gradient(rgba(0, 0, 0, 0) 60%, #00ecbc 60%);
  }
}

@media only screen and (max-width: 960px) {
  .scouts_price_block {
    padding: 40px 0 30px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_price_block .scouts_price_title {
    font-size: 20px;
    margin: 0 0 20px;
  }
}

.scouts_price_text {
  font-size: 30px;
  color: #242424;
  font-weight: bold;
  margin: 0 0 25px;
  line-height: 1.5;
  letter-spacing: 1.2px;
}

@media only screen and (max-width: 960px) {
  .scouts_price_text {
    font-size: 15px;
    margin: 0 0 20px;
  }
}

.scouts_price {
  font-size: 60px;
  line-height: 1.5;
  letter-spacing: 1.2px;
  color: #DF0000;
  font-weight: 900;

  span {
    font-size: 100px;
    letter-spacing: -.5px;
  }

  small {
    font-size: 36px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_price {
    font-size: 35px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_price span {
    font-size: 55px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_price small {
    font-size: 14px;
  }
}

.scouts_qa_block {
  padding: 150px 0 100px;
}

@media only screen and (max-width: 960px) {
  .scouts_qa_block {
    padding: 60px 0 40px;
  }
}

.scouts_qa_list {
  max-width: 1136px;
  width: 100%;
  margin: 0 auto;

  .scouts_qa_dt {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin: 0 0 15px;

    .scouts_qa_icon {
      font-size: 48px;
      font-weight: 500;
      color: #00ECBC;
      width: 40px;
      min-width: 40px;
      margin: 0 25px 0 0;
      text-align: center;
    }

    .scouts_qa_title {
      font-size: 20px;
      letter-spacing: 1px;
      font-weight: bold;
      line-height: 1.5;
      padding: 6px 0 0;
    }
  }

  .scouts_qa_dd {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin: 0 0 50px;

    .scouts_qa_icon {
      font-size: 48px;
      font-weight: 500;
      color: #007ADF;
      width: 40px;
      min-width: 40px;
      margin: 0 25px 0 0;
      text-align: center;
    }

    .scouts_qa_text {
      font-size: 12px;
      line-height: 1.5;
      color: #242424;
      letter-spacing: .5px;
      font-weight: 500;
      padding: 12px 0 0;
    }
  }
}

@media only screen and (max-width: 960px) {
  .scouts_qa_list .scouts_qa_dt {
    margin: 0 0 5px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_qa_list .scouts_qa_dt .scouts_qa_icon {
    font-size: 30px;
    width: 26px;
    min-width: 26px;
    margin: 0 10px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_qa_list .scouts_qa_dt .scouts_qa_title {
    font-size: 15px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_qa_list .scouts_qa_dd {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_qa_list .scouts_qa_dd .scouts_qa_icon {
    font-size: 30px;
    width: 26px;
    min-width: 26px;
    margin: 0 10px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .scouts_qa_list .scouts_qa_dd .scouts_qa_text {
    font-size: 11px;
    padding: 6px 0 0;
  }
}

/*=================
     
    progress.scss

=======================*/

.progress_wrap {
  margin-top: 20vh;
}

.progress_tip {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  font-size: 12px;
  line-height: 1.5;
  color: #242424;
  margin: 0 0 50px;
  letter-spacing: 1.2px;

  .label {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background: #00ECBC;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    margin: 0 20px 0 0;
  }

  .text {
    padding: 14px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .progress_tip {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 960px) {
  .progress_tip .label {
    padding: 5px 10px;
    font-size: 14px;
    margin: 0 10px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .progress_tip .text {
    padding: 6px 0 0;
  }
}

.progress_title_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 0 20px;

  .progress_title_left {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}

@media only screen and (max-width: 840px) {
  .progress_title_block .progress_title_left {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .progress_title_block.special {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .progress_title_block.special .progress_title_left {
    margin: 0 0 10px;
  }
}

.progress_select {
  outline: none;
  width: 160px;
  height: 30px;
  border: 1px #D4D4D4 solid;
  color: #A8A8A8;
  font-size: 12px;
  font-weight: 500;
  background: url("/images/common/icon08.svg") no-repeat #fff;
  background-size: 10px;
  background-position: right 10px center;
  padding: 0 30px 0 12px;
  letter-spacing: 1.2px;
  margin: 0 0 0 30px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
}

@media only screen and (max-width: 960px) {
  .progress_select {
    margin: 0 0 0 10px;
    font-size: 11px;
  }
}

@media only screen and (max-width: 840px) {
  .progress_select {
    margin: 10px 0 0;
  }
}

.progress_table_block {
  background: #fff;
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  margin: 0 auto 50px;
  overflow-x: scroll;

  .progress_table_inner {
    min-width: 1000px;
  }

  table {
    width: 100%;
    table-layout: fixed;
  }

  .tbl-content {
    background: #fff;
    max-height: 340px;
    min-height: 160px;
    overflow-x: auto;
    margin-top: 0px;
  }

  .progress_table_title {
    font-size: 12px;
    color: #A8A8A8;
    letter-spacing: 1.2px;
    line-height: 1.5;
    padding: 10px;
    font-weight: 500;
    border-bottom: 1px #EBEBEB solid;

    &.company {
      width: 20%;
    }

    &.info {
      width: 37%;
    }

    &.status {
      width: calc( 23% - 110px );
    }

    &.time {
      width: 10%;
    }

    &.bntarea {
      width: 110px;
    }
  }

  .progress_table_text {
    display: inline-block;
    padding: 5px 10px;
    color: #242424;
    line-height: 1.5;
    font-size: 14px;

    &.company {
      width: 20%;
    }

    &.info {
      width: 37%;

      span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }

    &.status {
      width: calc( 23% - 110px );
    }

    &.time {
      width: 10%;
    }

    &.bntarea {
      width: 110px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .progress_table_block .tbl-content {
    max-height: 250px;
  }
}

.progress_table_btn {
  display: block;
  width: 90px;
  height: 30px;
  line-height: 30px;
  background: #007ADF;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  text-align: center;
  text-decoration: none;
  margin: 0 auto;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.progress_addbtn {
  display: block;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 40px;
  height: 40px;
  max-width: 286px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzY4YjhmYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -moz-linear-gradient(0deg, #007adf, #68b8fa);
  background: -webkit-linear-gradient(0deg, #007adf, #68b8fa);
  background: linear-gradient(90deg, #007adf, #68b8fa);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  &.w180 {
    max-width: 180px;
  }
}

@media only screen and (max-width: 640px) {
  .progress_addbtn {
    height: 30px;
    line-height: 30px;
    font-size: 11px;
  }
}

.progress_edit_btn {
  display: block;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 40px;
  height: 40px;
  width: 100px;
  text-align: center;
  text-decoration: none;
  background: #007ADF;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  img {
    display: inline-block;
    width: 13px;
    margin: 0 5px 0 0;
    vertical-align: -1px;
  }
}

@media only screen and (max-width: 640px) {
  .progress_edit_btn {
    height: 30px;
    line-height: 30px;
    font-size: 11px;
  }
}

/* detail */

.progress_d_top {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 0 50px;

  .progress_d_top_main {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .progress_d_icon {
    display: block;
    width: 60px;
    height: 60px;
    min-width: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #EBEBEB;
    margin: 0 20px 0 0;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  .progress_d_company {
    font-size: 24px;
    font-weight: 900;
    color: #242424;
    line-height: 1.5;
    letter-spacing: 1.2px;
    padding: 12px 0 0;
  }

  .progress_edit_btn {
    min-width: 100px;
    margin: 10px 0 0 20px;
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_top {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 500px) {
  .progress_d_top {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_top .progress_d_icon {
    width: 45px;
    height: 45px;
    min-width: 45px;
    margin: 0 12px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_top .progress_d_company {
    font-size: 18px;
    padding: 8px 0 0;
  }
}

@media only screen and (max-width: 640px) {
  .progress_d_top .progress_edit_btn {
    margin: 7px 0 0 20px;
  }
}

@media only screen and (max-width: 500px) {
  .progress_d_top .progress_edit_btn {
    margin: 10px 0 0;
  }
}

.progress_d_layout {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  .progress_d_left, .progress_d_right {
    width: calc( 50% - 30px );
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_layout {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_layout .progress_d_left {
    display: block;
    width: 100%;
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_layout .progress_d_right {
    display: block;
    width: 100%;
  }
}

.progress_d_title {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1.2px;
  color: #242424;
  font-weight: bold;
  margin: 0 0 15px;
}

@media only screen and (max-width: 960px) {
  .progress_d_title {
    font-size: 15px;
    margin: 0 0 10px;
  }
}

.progress_d_inner {
  text-align: center;
  padding: 20% 30px;
  background: #fff;
  border: 2px solid #b2b2b2;
}

@media only screen and (max-width: 960px) {
  .progress_d_inner {
    padding: 10% 12px;
  }
}

.progress_d_title {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin-top: 22px;
}

.progress_d_icon {
  min-height: 50px;
}

.progress_d_history_list {
  .progress_d_history_item {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin: 0 0 10px;

    &:last-of-type {
      margin: 0;
    }
  }

  .progress_d_history_date {
    font-size: 12px;
    color: #A8A8A8;
    font-weight: 500;
    letter-spacing: 1.2px;
    line-height: 1.5;
    margin: 0 30px 0 0;
    white-space: nowrap;
    padding: 3px 0 0;
  }

  .progress_d_history_text {
    font-size: 14px;
    color: #242424;
    letter-spacing: 1.2px;
    line-height: 1.5;

    span {
      display: inline-block;
      font-size: 11px;
      padding: 3px 10px;
      color: #fff;
      margin: 2px 0 2px 10px;
      vertical-align: 1px;
      background: #00ECBC;
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
      border-radius: 20px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_history_list .progress_d_history_date {
    font-size: 11px;
    margin: 0 15px 0 0;
    padding: 6px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_history_list .progress_d_history_text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_history_list .progress_d_history_text span {
    font-size: 10px;
  }
}

.progress_d_table {
  width: 100%;
  font-size: 14px;
  letter-spacing: 1.2px;
  line-height: 1.5;
  color: #242424;

  .progress_d_th {
    vertical-align: top;
    width: 105px;
    padding: 0 25px 12px 0;
  }

  .progress_d_td {
    vertical-align: top;
    font-weight: bold;
    padding: 0 0 12px;

    &.green {
      color: #00ECBC;
    }
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_table {
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_table .progress_d_th {
    width: 100px;
    padding: 0 12px 12px 0;
  }
}

.progress_d_status_now {
  font-size: 14px;
  color: #00ECBC;
  font-weight: 500;
  letter-spacing: 1.2px;
  line-height: 1.5;
  margin: 0 0 10px;

  span {
    color: #242424;
    margin: 0 5px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .progress_d_status_now {
    font-size: 12px;
    margin: 0 0 5px;
  }
}

.progress_status_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 0 10px;

  .profile_edit_select, .profile_edit_input {
    margin: 0 10px 0 0;
  }

  .profile_license_delete {
    margin: 0 0 0 10px;
  }
}

.progress_d_upload {
  display: block;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 40px;
  height: 40px;
  max-width: 286px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzY4YjhmYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -moz-linear-gradient(0deg, #007adf, #68b8fa);
  background: -webkit-linear-gradient(0deg, #007adf, #68b8fa);
  background: linear-gradient(90deg, #007adf, #68b8fa);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  .progress_d_upload_input {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .progress_d_upload {
    height: 30px;
    line-height: 30px;
    font-size: 11px;
  }
}

.progress_d_upload_pic {
  margin: 10px 0;

  img {
    max-width: 100%;
    width: auto;
  }
}

.progress_d_upload_text {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 1.2px;
  color: #242424;
  margin: 10px 0 0;

  span {
    color: #00ECBC;
  }
}

.progress_d_status_text {
  font-size: 16px;
  padding: 5px 0 0;
  color: #00ECBC;
  line-height: 1.5;
  letter-spacing: 1.2px;
}

@media only screen and (max-width: 960px) {
  .progress_d_status_text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 640px) {
  .progress_d_status_text {
    padding: 0;
  }
}

/*=================
     
    others.scss

=======================*/

.others_wrap {
  padding: 0 0 100px;
}

@media only screen and (max-width: 960px) {
  .others_wrap {
    padding: 0 0 50px;
  }
}

.others_block {
  max-width: 800px;
  width: 100%;
  margin: 0 auto 40px;
}

.others_title {
  font-size: 20px;
  color: black;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.5px;
  margin: 0 0 15px;
}

@media only screen and (max-width: 960px) {
  .others_title {
    font-size: 18px;
  }
}

.others_text {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: black;
  margin: 0 0 15px;
}

@media only screen and (max-width: 960px) {
  .others_text {
    font-size: 12px;
  }
}

.others_list {
  padding: 0 0 0 20px;
  margin: 0 0 15px;

  .others_item {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 1.5px;
    color: #535353;
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 960px) {
  .others_list .others_item {
    font-size: 12px;
  }
}

@font-face {
  font-family: 'Futura LT';
  src: url("../fonts/FuturaLT.ttf");
}

@font-face {
  font-family: 'Futura LT - Book';
  src: url("../fonts/FuturaBook.ttf");
}

/* ======================= BASE ====== */

* {
  box-sizing: border-box;
}

html {
  position: relative;
  margin: 0;
  height: 100%;
  overflow-x: hidden;
}

body {
  position: relative;
  margin: 0;
  height: 100%;
  overflow-x: hidden;

  &.no_scroll {
    overflow: hidden;
  }
}

.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.fm {
  font-family: "Futura LT", serif;
}

.fg, .common_title02, .common_title03, .common_title04, .common_btn02, .common_btn03 {
  font-family: "Futura LT", Verdana, sans-serif;
}

.header_type01 .header_nav {
  .header_list .header_link, .header_btn {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.header_type02 {
  .header_user, .header_logout_btn, .header_list .header_link, .header_sp_list .header_link {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.footer_wrap .footer_list .footer_link, .common_column_link .common_column_title, .common_column_category, .common_column_rank {
  font-family: "Futura LT", Verdana, sans-serif;
}

.common_cv_block {
  .common_cv_title, .common_cv_text {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.common_cv_btn, .common_select, .common_input, .common_textarea, .common_pager_block .common_pager_list .common_pager_link, .top_mv_block .top_mv_title {
  font-family: "Futura LT", Verdana, sans-serif;
}

.top_about_block {
  .top_about_title, .top_about_text {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.top_point_block {
  .top_point_subtitle, .top_point_text {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.top_company_block .top_company_title, .login_title, .login_fb_btn, .login_other_text, .login_input, .login_reset_link, .login_complete_text {
  font-family: "Futura LT", Verdana, sans-serif;
}

.register_wrap {
  .register_sub .register_sub_text {
    font-family: "Futura LT", Verdana, sans-serif;
  }

  .register_main {
    .register_title, .register_text {
      font-family: "Futura LT", Verdana, sans-serif;
    }
  }
}

.register_complete_text, .custom-select-trigger, .custom-option, .mypage_mail_btn, .mypage_mail_info, .mypage_calendar_tab .mypage_calendar_tab_item, .mypage_calendar_item, .mypage_popup_block .mypage_popup_title, .mypage_popup_box .mypage_form_title, .mypage_form_input, .mypage_popup_top, .mypage_zip_btn, .mailbox_search_block .mailbox_search_input, .mailbox_pager_block .mailbox_pager_text, .mailbox_menu_list .mailbox_link {
  font-family: "Futura LT", Verdana, sans-serif;
}

.mailbox_table {
  .mailbox_title, .mailbox_detail {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.mailbox_list .mailbox_item, .mailbox_all_delete_btn, .mailbox_d_title_block, .mailbox_d_date, .mailbox_d_from, .mailbox_d_editor, .mailbox_common_btn01, .mailbox_common_btn02, .mailbox_block_btn, .mailbox_send_title_block .mailbox_send_title, .mailbox_send_textarea, .mailbox_send_btn {
  font-family: "Futura LT", Verdana, sans-serif;
}

.mailbox_dl {
  .mailbox_dt_text, .mailbox_dd_text, .mailbox_dd_subtext {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.contact_title, .contact_complete_text, .profile_user_block .profile_user_name, .profile_edit_btn, .profile_table, .profile_dl, .profile_text, .profile_company_none, .profile_company_table, .profile_company_delete_btn, .profile_unsubscribe_btn, .profile_contact_btn, .modal_block .profile_upload_textarea, .profile_upload_btn, .profile_edit_table .profile_edit_title, .profile_edit_error_msg, .profile_edit_input, .profile_edit_select, .profile_birth_block, .profile_zip_block, .profile_edit_subtitle, .profile_company_block_text, .column_info_block .column_info_num, .column_info_select {
  font-family: "Futura LT", Verdana, sans-serif;
}

.column_list {
  .column_title, .column_text {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.column_aside_title, .column_category_list .column_category_link, .column_keyword_list .column_keyword_link, .column_d_title {
  font-family: "Futura LT", Verdana, sans-serif;
}

.column_d_editor_block {
  font-family: "Futura LT", Verdana, sans-serif;

  h2, h3, h4 {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.scouts_mv_block .scouts_mv_title {
  font-family: "Futura LT", Verdana, sans-serif;
}

.scouts_about_block {
  .scouts_about_title, .scouts_about_text {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.scouts_cv_block, .scouts_common_title, .scouts_price_block .scouts_price_title, .scouts_price_text, .scouts_price {
  font-family: "Futura LT", Verdana, sans-serif;
}

.scouts_qa_list {
  .scouts_qa_dt .scouts_qa_title, .scouts_qa_dd .scouts_qa_text {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.progress_tip, .progress_select {
  font-family: "Futura LT", Verdana, sans-serif;
}

.progress_table_block {
  .progress_table_title, .progress_table_text {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.progress_table_btn, .progress_addbtn, .progress_edit_btn, .progress_d_top .progress_d_company, .progress_d_title {
  font-family: "Futura LT", Verdana, sans-serif;
}

.progress_d_history_list {
  .progress_d_history_date, .progress_d_history_text {
    font-family: "Futura LT", Verdana, sans-serif;
  }
}

.progress_d_table, .progress_d_status_now, .progress_d_upload, .progress_d_upload_text, .progress_d_status_text, .others_title, .others_text, .others_list .others_item, .group_input, .fe, .common_title01, .common_btn01, .common_btn04, .footer_contact_btn, .footer_copyright, .top_mv_block .top_mv_subtitle, .top_point_block .top_point_title, .login_copyright, .login_submit_btn, .register_wrap .register_sub .register_sub_title, .column_ranking_title, .scouts_mv_block .scouts_mv_subtitle {
  font-family: "Futura LT", Verdana, sans-serif;
}

.fa, .common_column_link .common_column_date, .column_list .column_pic .column_date {
  font-family: "Futura LT", Verdana , sans-serif;
}

.scouts_qa_list {
  .scouts_qa_dt .scouts_qa_icon, .scouts_qa_dd .scouts_qa_icon {
    font-family: "Futura LT", Verdana , sans-serif;
  }
}

.progress_d_inner, .btn_footer_modal, .header_top_title, .Toastify__toast-body {
  font-family: "Futura LT", Verdana , sans-serif;
}

.room_result .room_result_item, .modal_block, .step-bar .step-bar-item, .booking_new_info .booking_new_input, .select2-container--debeer, .booking_new_table, .btn-booking-confirm, .lightpick, .booking_manager, .btn_raise, .btn_booking_manager {
  font-family: "Futura LT - Book";
}

.common_inner {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 1440px) {
  .common_inner {
    padding: 0 12px;
  }
}

.common_inner_small {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 1140px) {
  .common_inner_small {
    padding: 0 12px;
  }
}

.common_btn02, .common_btn03, .common_btn04 {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header_type01 {
  .header_wrap .header_logo {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .header_nav {
    .header_list .header_link, .header_btn {
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  }
}

.header_type02 {
  .header_top .header_logo, .header_list .header_link {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.footer_wrap .footer_list .footer_link, .footer_contact_btn, .footer_logo, .common_column_link, .common_cv_btn {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.common_pager_block {
  .common_pager_perv, .common_pager_next, .common_pager_list .common_pager_link {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.login_fb_btn, .login_submit_btn, .login_reset_link, .register_wrap .register_main .register_text a, .mypage_mail_btn, .mypage_mail_list .mypage_mail_link, .mypage_calendar_tab .mypage_calendar_tab_item, .mypage_calendar_btn, .mypage_zip_btn, .mailbox_search_block .mailbox_menu_btn {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.mailbox_pager_block {
  .mailbox_prev, .mailbox_next {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.mailbox_menu_list .mailbox_link, .mailbox_delete_btn, .mailbox_all_delete_btn, .mailbox_d_delete_btn, .mailbox_common_btn01, .mailbox_common_btn02, .mailbox_block_btn, .mailbox_send_btn, .mailbox_dl .mailbox_dt, .profile_user_block .profile_user_btn, .profile_edit_btn, .profile_dl .profile_dt img, .profile_company_delete_btn, .profile_contact_btn, .profile_upload_btn, .profile_license_delete, .column_list .column_link, .column_category_list .column_category_link, .column_keyword_list .column_keyword_link {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.column_d_sns_block {
  .column_d_fb_btn, .column_d_twitter_btn {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.scouts_cv_btn, .progress_table_btn, .progress_addbtn, .progress_edit_btn, .progress_d_upload {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.common_btn02:hover, .common_btn03:hover, .common_btn04:hover {
  opacity: 0.8;
}

.header_type01 {
  .header_wrap .header_logo:hover {
    opacity: 0.8;
  }

  .header_nav {
    .header_list .header_link:hover, .header_btn:hover {
      opacity: 0.8;
    }
  }
}

.header_type02 {
  .header_top .header_logo:hover, .header_list .header_link:hover {
    opacity: 0.8;
  }
}

.footer_wrap .footer_list .footer_link:hover, .footer_contact_btn:hover, .footer_logo:hover, .common_column_link:hover, .common_cv_btn:hover {
  opacity: 0.8;
}

.common_pager_block {
  .common_pager_perv:hover, .common_pager_next:hover, .common_pager_list .common_pager_link:hover {
    opacity: 0.8;
  }
}

.login_fb_btn:hover, .login_submit_btn:hover, .login_reset_link:hover, .register_wrap .register_main .register_text a:hover, .mypage_mail_btn:hover, .mypage_mail_list .mypage_mail_link:hover, .mypage_calendar_tab .mypage_calendar_tab_item:hover, .mypage_calendar_btn:hover, .mypage_zip_btn:hover, .mailbox_search_block .mailbox_menu_btn:hover {
  opacity: 0.8;
}

.mailbox_pager_block {
  .mailbox_prev:hover, .mailbox_next:hover {
    opacity: 0.8;
  }
}

.mailbox_menu_list .mailbox_link:hover, .mailbox_delete_btn:hover, .mailbox_all_delete_btn:hover, .mailbox_d_delete_btn:hover, .mailbox_common_btn01:hover, .mailbox_common_btn02:hover, .mailbox_block_btn:hover, .mailbox_send_btn:hover, .mailbox_dl .mailbox_dt:hover, .profile_user_block .profile_user_btn:hover, .profile_edit_btn:hover, .profile_dl .profile_dt img:hover, .profile_company_delete_btn:hover, .profile_contact_btn:hover, .profile_upload_btn:hover, .profile_license_delete:hover, .column_list .column_link:hover, .column_category_list .column_category_link:hover, .column_keyword_list .column_keyword_link:hover {
  opacity: 0.8;
}

.column_d_sns_block {
  .column_d_fb_btn:hover, .column_d_twitter_btn:hover {
    opacity: 0.8;
  }
}

.scouts_cv_btn:hover, .progress_table_btn:hover, .progress_addbtn:hover, .progress_edit_btn:hover, .progress_d_upload:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 960px) {
  .common_btn02:hover, .common_btn03:hover, .common_btn04:hover {
    opacity: 1;
  }

  .header_type01 {
    .header_wrap .header_logo:hover {
      opacity: 1;
    }

    .header_nav {
      .header_list .header_link:hover, .header_btn:hover {
        opacity: 1;
      }
    }
  }

  .header_type02 {
    .header_top .header_logo:hover, .header_list .header_link:hover {
      opacity: 1;
    }
  }

  .footer_wrap .footer_list .footer_link:hover, .footer_contact_btn:hover, .footer_logo:hover, .common_column_link:hover, .common_cv_btn:hover {
    opacity: 1;
  }

  .common_pager_block {
    .common_pager_perv:hover, .common_pager_next:hover, .common_pager_list .common_pager_link:hover {
      opacity: 1;
    }
  }

  .login_fb_btn:hover, .login_submit_btn:hover, .login_reset_link:hover, .register_wrap .register_main .register_text a:hover, .mypage_mail_btn:hover, .mypage_mail_list .mypage_mail_link:hover, .mypage_calendar_tab .mypage_calendar_tab_item:hover, .mypage_calendar_btn:hover, .mypage_zip_btn:hover, .mailbox_search_block .mailbox_menu_btn:hover {
    opacity: 1;
  }

  .mailbox_pager_block {
    .mailbox_prev:hover, .mailbox_next:hover {
      opacity: 1;
    }
  }

  .mailbox_menu_list .mailbox_link:hover, .mailbox_delete_btn:hover, .mailbox_all_delete_btn:hover, .mailbox_d_delete_btn:hover, .mailbox_common_btn01:hover, .mailbox_common_btn02:hover, .mailbox_block_btn:hover, .mailbox_send_btn:hover, .mailbox_dl .mailbox_dt:hover, .profile_user_block .profile_user_btn:hover, .profile_edit_btn:hover, .profile_dl .profile_dt img:hover, .profile_company_delete_btn:hover, .profile_contact_btn:hover, .profile_upload_btn:hover, .profile_license_delete:hover, .column_list .column_link:hover, .column_category_list .column_category_link:hover, .column_keyword_list .column_keyword_link:hover {
    opacity: 1;
  }

  .column_d_sns_block {
    .column_d_fb_btn:hover, .column_d_twitter_btn:hover {
      opacity: 1;
    }
  }

  .scouts_cv_btn:hover, .progress_table_btn:hover, .progress_addbtn:hover, .progress_edit_btn:hover, .progress_d_upload:hover {
    opacity: 1;
  }
}

.common_pc {
  display: block;

  &.height_100 {
    height: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .common_pc {
    display: none;
  }
}

.common_sp {
  display: none;
}

@media only screen and (max-width: 960px) {
  .common_sp {
    display: block;
  }
}

.group_input {
  margin: 0 auto 25px;
  position: relative;

  .label_input {
    color: #656565;
  }
}

.login_remember_group {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}

.login_forgot_link {
  color: #0d1a29;
  margin: 10px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.login_wrap_fix {
  padding: 0;
  background: #ffffff;

  .register_sub {
    height: 100vh;
    width: 40%;
  }

  .register_main {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .register_inner {
    max-width: 100%;
  }

  .login_body {
    max-width: 358px;
    width: 100%;
    display: flex;
    align-self: center;
    form#form_login {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 960px) {
  .login_wrap_fix .login_body {
    padding: 0 16px;
  }
}

.background_filter {
  -webkit-filter: blur(3px);
  -o-filter: blur(3px);
  -moz-filter: blur(3px);
  filter: blur(3px);
}

.modal_sub_title {
  width: 85%;
  color: #6c6c6c;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;
}

.group_footer_modal_button {
  margin-top: 15px;
}

.btn_footer_modal {
  outline: none;
  border: 1px solid #0d1a29;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  color: #0e1b2f;
  position: relative;
  display: inline-block;
  width: 48%;
  max-width: 170px;
  height: 46px;
  cursor: pointer;
}

.btn_modal_cancel {
  float: left;
}

.btn_modal_confirm {
  float: right;
  color: #ffffff;
  background-color: #0d1a29;
}

.header_top_group_logo {
  display: inline-flex;
}

.header_top_title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding-left: 57px;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-grow: 1;
}

@media only screen and (max-width: 640px) {
  .header_top_title {
    font-size: 12px;
    flex-grow: 0;
    padding: 0;
  }
}

.none-decoration {
  text-decoration: none;
}

/* step booking */

.step-bar-wrap {
  height: 55px;
  width: 100%;
  background-color: #ececec;
  z-index: 50;
}

.step-bar-inner {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

.step-bar {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 55px;
}

.step-bar-item {
  background-color: #ececec;
  width: 100%;
  height: 100%;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  display: inline-flex;

  p {
    margin: auto;
    opacity: 0.39;
    color: #4b4b4b;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
  }

  &.active {
    background-color: #c7cbd6;
    border: none;

    p {
      opacity: 0.9;
      color: #0d1a29;
      font-weight: 500;
      white-space: nowrap;
    }
  }
}

@media only screen and (max-width: 960px) {
  .step-bar-wrap {
    position: fixed;
    top: 60px;
  }

  .step-bar-item p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 640px) {
  .step-bar-wrap {
    position: fixed;
    top: 50px;
  }

  .step-bar-item {
    white-space: nowrap;

    p {
      font-size: 11px;
      text-transform: capitalize;
      padding: 0 5px;
    }
  }

  .step-bar, .step-bar-wrap {
    height: 40px;
  }
}

/*=================
     
      booking.scss

=======================*/

.booking_new_wrap {
  padding: 35px 0 100px;
}

.booking_new_table {
  max-width: 430px;
  width: 100%;
  margin: 0 auto 15px;

  .booking_new_title {
    text-align: left;
    vertical-align: top;
    font-size: 14px;
    color: #000000;
    font-weight: 500;
    padding: 15px 0 15px;
    width: 185px;

    .booking_new_sub_title {
      font-size: 12px;
      color: #7e7e7e;
    }
  }

  .booking_new_info {
    padding: 0 0 15px 30px;
  }
}

.booking_new_info .booking_new_input {
  display: block;
  max-width: 500px;
  width: 100%;
  border: none;
  border: 1px solid #b8b8b8;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  height: 44px;
  padding: 15px;
  outline: none;
}

.btn-booking-confirm {
  color: #ffffff;
  background-color: #0d1a29;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 46px;
  cursor: pointer;
}

@media only screen and (max-width: 640px) {
  .booking_new_wrap {
    padding-top: 0;
  }

  .booking_new_table {
    .booking_new_title {
      font-size: 12px;
      display: block;
      width: 100%;
      padding: 20px 0 15px 0;
    }

    .booking_new_info {
      display: block;
      width: 100%;
      padding: 0;
    }
  }
}

.booking_calendar_block {
  max-width: 430px;
  width: 100%;
  margin: 0 auto 15px;
}

.booking_new_person_number {
  display: flex;
  height: 44px;

  .booking_new_input {
    height: auto;
  }

  .button_person_number {
    outline: none;
    border: 46;
    width: 30%;
    border: 1px solid #b8b8b8;
    background: #fff;
  }

  .button_person_number_minus {
    border-right: none;

    img {
      top: -3px;
      position: relative;
    }
  }

  .button_person_number_plus {
    border-left: none;
  }

  .booking_new_input {
    text-align: center;
    width: 50%;
  }
}

.booking_new_box {
  border: 1px solid #b8b8b8;
  padding: 15px 20px 20px 20px;
  margin-bottom: 15px;

  .booking_new_box_title {
    font-size: 12px;
    color: #7e7e7e;
    margin-bottom: 7px;
  }

  .booking_new_box_body {
    font-size: 12px;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 640px) {
  .booking_new_box {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.modal_d_table {
  width: 100%;
  font-size: 16px;
  letter-spacing: 1.2px;
  line-height: 1.5;
  color: #242424;

  .modal_d_th {
    vertical-align: top;
    width: 155px;
    padding: 0 25px 5px 0;
    white-space: nowrap;
  }

  .modal_d_td {
    vertical-align: top;
    padding: 0 0 5px;
  }
}

@media only screen and (max-width: 960px) {
  .modal_d_table {
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .modal_d_table .modal_d_th {
    width: 100px;
    padding: 0 12px 5px 0;
  }
}

.booking_manager {
  margin-top: 15px;
}

.booking_manager_header {
  display: flex;
  justify-content: space-between;

  .booking_manager_filter {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 30%;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: flex-start;
    align-items: center;
  }
}

.booking_manager_filter .booking_manager_filter_title {
  font-size: 14px;
  white-space: nowrap;
  margin-right: 15%;
}

.btn_raise {
  outline: none;
  color: #ffffff;
  background-color: #0d1a29;
  border: 1px solid #0d1a29;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  width: 48%;
  height: 46px;
  cursor: pointer;
  white-space: nowrap;
}

.booking_manager_header .btn_booking_manager {
  flex-basis: 25%;
}

.btn_booking_manager_sp {
  display: none;
}

.booking_manager_header .btn_booking_manager_pc {
  min-width: 235px;
}

@media only screen and (max-width: 960px) {
  .booking_manager_header {
    .btn_booking_manager, .booking_manager_filter {
      flex-basis: 40%;
    }
  }
}

@media only screen and (max-width: 640px) {
  .booking_manager_header {
    .btn_booking_manager_pc {
      display: none;
    }

    .booking_manager_filter {
      flex-basis: 100%;
    }
  }

  .btn_booking_manager_sp {
    display: block;
    width: 100%;
    position: sticky;
    bottom: 14px;

    /* right: 0; */
    outline: 15px solid #fff;
  }
}

.booking_manager_body {
  margin-top: 48px;
}

.booking_manager_box_not_found {
  border: 3px solid #294e79;
  padding: 35px 30px;
  text-align: center;
  font-size: 18px;
}

.booking_manager_box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  border: 3px solid #294e79;
  padding: 20px 30px;
  flex-wrap: wrap;
  margin-bottom: 16px;

  .booking_manager_box_col {
    flex: 1 1 5%;
    .box_header {
      color: #b0b0b0;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    .box_body {
      margin-top: 4px;
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
    }

    .btn_booking_manager {
      outline: none;
      border: 1px solid #0d1a29;
      background-color: white;
      font-size: 11px;
      height: 37px;
      padding: 0 30px;
      text-decoration: none;
      color: #0d1a29;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 2px;
      }
    }
  }
  .booking_action {
    flex: 0 0 21%;
    display: flex;
    justify-content: space-between;
  }
}

.booking_manager_box_disable {
  border: 3px solid #ececec;
  background-color: #ececec;
}

@media only screen and (max-width: 960px) {
  .booking_manager_body {
    margin-top: 16px;
    margin-bottom: 30px;
  }

  .booking_manager_box .booking_manager_box_col {
    flex: 30%;

    .btn_booking_manager {
      width: 100%;
    }

    .box_body {
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .booking_manager_box .booking_manager_box_col {
    flex: 35%;

    .box_body {
      margin-bottom: 17px;
      font-size: 12px;
    }

    .box_header {
      font-size: 10px;
    }
  }
}

@media only screen and (max-width: 335px) {
  .booking_manager_box .booking_manager_box_col .btn_booking_manager {
    width: 100%;
    margin-top: 10px;
  }
}

.ranger_time_picker {
  padding: 20px 0 40px;
}

.room_result {
  height: 306px;
  border: 2px solid #294e79;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.room_result_item {
  outline: none;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #545454;
  font-size: 14px;
  font-weight: 500;
  width: 96%;
  height: 46px;
  cursor: pointer;
  white-space: nowrap;
  margin: 7px auto;

  &:hover, &:focus {
    background-color: #0d1a29;
    color: #f3b229;
  }
}

.ranger_time_result {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin: 10px 0;
}

.modal_room_picker {
  max-width: 1100px !important;
  overflow-y: auto;
  height: 90vh;
  max-height: 864px;
}

.modal_room_picker_footer {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  .room_picker_confirm_link {
    flex: 0 0 17%;
  }
}

.room_picker_confirm_link {
  .btn_footer_modal {
    width: 100%;
    max-width: none;
  }

  .btn_modal_confirm {
    color: #ffffff;
  }
}

.modal_room_picker_footer strong {
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .modal_room_picker {
    max-height: 792px;
  }

  .modal_room_picker_footer {
    font-size: 12px;

    .modal_room_picker_result {
      display: none;
    }

    .room_picker_confirm_link {
      flex: 0 0 50%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .modal_room_picker {
    max-height: 732px;
  }
}

.hidden {
  display: none;
}

.booking_time_warning {
  text-align: center;
  margin-bottom: 5px;
}
@media only screen and (max-width: 640px) {
  .booking_time_warning {
    font-size: 12px;
  }
}