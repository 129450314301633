/*=================
     
      mypage.scss

=======================*/

.mypage_wrap {
  padding: 55px 0 150px;
}

@media only screen and (max-width: 960px) {
  .mypage_wrap {
    padding: 30px 0 75px;
  }
}

.mypage_block {
  margin: 0 0 50px;

  &:last-of-type {
    margin: 0;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_block {
    margin: 0 0 40px;
  }
}

.mypage_title_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.mypage_mail_btn {
  display: block;
  width: 200px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  letter-spacing: 1.5px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  text-align: center;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzY4YjhmYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -moz-linear-gradient(0deg, #007adf, #68b8fa);
  background: -webkit-linear-gradient(0deg, #007adf, #68b8fa);
  background: linear-gradient(90deg, #007adf, #68b8fa);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  img {
    display: inline-block;
    width: 18px;
    margin: 0 10px 0 0;
    vertical-align: -2px;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_mail_btn {
    max-width: 335px;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    line-height: 50px;
  }
}

.mypage_mail_block {
  background: #fff;
  height: 388px;
  padding: 30px;
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 960px) {
  .mypage_mail_block {
    padding: 20px;
    height: 300px;
    margin: 0 0 20px;
  }
}

.mypage_mail_list {
  height: calc( 388px - 60px );
  overflow-y: scroll;

  .mypage_mail_item {
    margin: 0 0 20px;

    &:last-of-type {
      margin: 0;
    }
  }

  .mypage_mail_link {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    text-decoration: none;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_mail_list {
    height: calc( 300px - 40px );
  }
}

.mypage_mail_dot {
  display: block;
  width: 15px;
  height: 15px;
  min-width: 15px;
  margin: 8px 15px 0 0;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

@media only screen and (max-width: 960px) {
  .mypage_mail_dot {
    width: 10px;
    height: 10px;
    min-width: 10px;
    margin: 5px 10px 0 0;
  }
}

.mypage_mail_info {
  width: calc( 100% - 30px );

  .mypage_mail_title {
    font-size: 16px;
    line-height: 1.5;
    color: #242424;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin: 0 0 5px;
  }

  .mypage_mail_date {
    font-size: 12px;
    line-height: 1.5;
    color: #A8A8A8;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_mail_info {
    width: calc( 100% - 20px );
  }
}

@media only screen and (max-width: 960px) {
  .mypage_mail_info .mypage_mail_title {
    font-size: 13px;
  }
}

.mypage_calendar_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background: #fff;
  padding: 75px 70px 70px;
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);

  .mypage_calendar {
    width: 60%;
  }

  .mypage_calendar_sub {
    width: 37%;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_calendar_block {
    padding: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .mypage_calendar_block {
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .mypage_calendar_block .mypage_calendar {
    width: 100%;
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 750px) {
  .mypage_calendar_block .mypage_calendar_sub {
    width: 100%;
  }
}

.mypage_calendar_tab {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 0 30px;

  .mypage_calendar_tab_item {
    margin: 0 15px 0 0;
    display: inline-block;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: #A8A8A8;
    background: #DFF8F3;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    cursor: pointer;

    &.active {
      background: #00ECBC;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 960px) {
  .mypage_calendar_tab {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_calendar_tab .mypage_calendar_tab_item {
    padding: 8px 15px;
    margin: 0 10px 0 0;
  }
}

.mypage_calendar_sub_inner {
  display: none;

  &.active {
    display: block;
  }
}

.mypage_calendar_list {
  margin: 0 0 30px;
}

.mypage_calendar_item {
  display: block;
  background: #007ADF;
  padding: 20px;
  color: #fff;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 0 auto 20px;
  width: 100%;

  &:last-of-type {
    margin: 0 auto;
  }

  .mypage_calendar_top {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    margin: 0 0 15px;
  }

  .mypage_calendar_date {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 500;
    margin: 0 20px 0 0;

    span {
      font-size: 11px;
      letter-spacing: 2px;
    }
  }

  .mypage_calendar_time {
    font-size: 14px;
    font-weight: 900;
  }

  .mypage_calendar_info {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .mypage_calendar_label {
    display: inline-block;
    white-space: nowrap;
    padding: 10px;
    font-size: 12px;
    letter-spacing: 1px;
    background: #fff;
    color: #007ADF;
    font-weight: bold;
    margin: 0 10px 0 0;
  }

  .mypage_calendar_detail {
    width: 100%;
    padding: 3px 0 0;
  }

  .mypage_calendar_company {
    word-break: break-word;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1.5px;
    font-weight: 900;
    margin: 0 0 5px;
  }

  .mypage_calendar_address {
    word-break: break-word;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1px;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_calendar_item .mypage_calendar_date {
    font-size: 13px;
    margin: 0 10px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_calendar_item .mypage_calendar_date span {
    font-size: 10px;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_calendar_item .mypage_calendar_time {
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_calendar_item .mypage_calendar_label {
    font-size: 11px;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_calendar_item .mypage_calendar_company {
    font-size: 14px;
  }
}

@media only screen and (max-width: 960px) {
  .mypage_calendar_item .mypage_calendar_address {
    font-size: 11px;
  }
}

.mypage_calendar_btn {
  outline: none;
  border: none;
  padding: 0;
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzY4YjhmYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -moz-linear-gradient(0deg, #007adf, #68b8fa);
  background: -webkit-linear-gradient(0deg, #007adf, #68b8fa);
  background: linear-gradient(90deg, #007adf, #68b8fa);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;

  img {
    position: absolute;
    width: 18px;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
}

/* popup */

.mypage_filter {
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 60;
  top: 0;
  left: 0;
  right: 0;
}

.mypage_popup_block {
  display: none;
  position: fixed;
  z-index: 61;
  background: #fff;
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding: 40px 50px;
  max-width: 600px;
  width: 95%;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  .mypage_popup_title {
    font-size: 16px;
    font-weight: 900;
    line-height: 1.5;
    color: #242424;
    margin: 0 0 20px;
    letter-spacing: 1px;
  }
}

@media only screen and (max-width: 500px) {
  .mypage_popup_block {
    padding: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .mypage_popup_block .mypage_popup_title {
    font-size: 14px;
    margin: 0 0 10px;
  }
}

.mypage_popup_box .mypage_form_title {
  font-size: 14px;
  color: #A8A8A8;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1px;
  margin: 0 0 10px;
}

@media only screen and (max-width: 500px) {
  .mypage_popup_box .mypage_form_title {
    font-size: 12px;
    margin: 0 0 5px;
  }
}

.mypage_form_input {
  display: block;
  width: 100%;
  height: 50px;
  border: 1px #D4D4D4 solid;
  background: #fff;
  outline: none;
  font-size: 14px;
  color: #333;
  letter-spacing: 1px;
  padding: 0 15px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;

  &.w100 {
    max-width: 100px;
  }

  &.w150 {
    max-width: 150px;
  }

  &.bg {
    background: url("/images/common/icon08.svg") no-repeat #fff;
    background-size: 14px;
    background-position: right 15px center;
    padding: 0 40px 0 15px;

    &.active {
      background: url("/images/common/icon10.svg") no-repeat #fff;
      background-size: 14px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .mypage_form_input {
    height: 40px;
  }
}

.mypage_popup_top {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 16px;
  color: #A8A8A8;
  letter-spacing: 1px;
  font-weight: bold;
  margin: 0 0 10px;

  .mypage_form_input {
    margin: 0 10px;
  }
}

.mypage_zip_btn {
  outline: none;
  display: block;
  width: 90px;
  height: 40px;
  padding: 0;
  border: none;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #fff;
  background: #D4D4D4;
}

@media only screen and (max-width: 500px) {
  .mypage_zip_btn {
    height: 30px;
    font-size: 10px;
  }
}

.mypage_popup_date {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  .date_box {
    width: 200px;
    padding: 0 20px 0 0;
  }

  .time_box {
    width: 300px;
  }
}

@media only screen and (max-width: 500px) {
  .mypage_popup_date {
    display: block;
  }
}

@media only screen and (max-width: 500px) {
  .mypage_popup_date .date_box {
    width: 100%;
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 500px) {
  .mypage_popup_date .time_box {
    max-width: 300px;
    width: 100%;
  }
}

.mypage_popup_time {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  img {
    display: block;
    width: 17px;
    margin: 0 10px;
  }

  .mypage_form_input {
    padding: 0 0 0 15px;
  }
}

.mypage_popup_btnarea {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  button {
    width: calc( 50% - 10px );
  }
}

@media only screen and (max-width: 500px) {
  .mypage_popup_btnarea button {
    width: calc( 50% - 5px );
  }
}

