/*=================
     
      profile.scss

=======================*/

.profile_layout {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 100px 0 80px;

  .profile_sub {
    width: 300px;
  }

  .profile_main {
    width: calc( 100% - 420px );
  }
}

@media only screen and (max-width: 960px) {
  .profile_layout {
    display: block;
    padding: 60px 0 40px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_layout .profile_sub {
    width: 100%;
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 640px) {
  .profile_layout .profile_sub {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .profile_layout .profile_main {
    width: 100%;
  }
}

.profile_user_block {
  margin: 0 0 40px;

  .profile_user_pic {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 300px;
    height: 300px;
    margin: 0 0 30px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  .profile_user_btn {
    position: absolute;
    right: 30px;
    bottom: 0;
    display: block;
    width: 60px;
    border: none;
    background: none;
    outline: none;
    padding: 0;

    img {
      display: block;
      width: 100%;
    }
  }

  .profile_user_name {
    text-align: center;
    word-break: break-word;
    font-size: 24px;
    font-weight: 900;
    line-height: 1.5;
    letter-spacing: 1.2px;
    color: #242424;

    span {
      display: block;
      font-size: 12px;
      font-weight: 500;
      margin: 5px 0 0;
    }
  }
}

@media only screen and (max-width: 960px) {
  .profile_user_block {
    margin: 0 20px 0 0;
  }
}

@media only screen and (max-width: 640px) {
  .profile_user_block {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_user_block .profile_user_pic {
    width: 200px;
    height: 200px;
    min-width: 200px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 640px) {
  .profile_user_block .profile_user_pic {
    margin: 0 auto 30px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_user_block .profile_user_btn {
    right: 15px;
    width: 40px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_user_block .profile_user_name {
    font-size: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_user_block .profile_user_name span {
    font-size: 10px;
    margin: 0;
  }
}

.profile_badge_block {
  background: #fff;
  padding: 20px 30px;
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 960px) {
  .profile_badge_block {
    width: 100%;
    padding: 10px 15px;
    margin: 30px 0 0;
  }
}

@media only screen and (max-width: 640px) {
  .profile_badge_block {
    margin: 0;
  }
}

.profile_badge_list {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;

  .profile_badge_item {
    width: calc( (100% - 40px) / 3 );
    text-align: center;
    margin: 0 20px 10px 0;

    &:nth-of-type(3n) {
      margin: 0 0 10px;
    }
  }

  img {
    display: inline-block;
    max-width: 66px;
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .profile_badge_list .profile_badge_item:nth-of-type(3n) {
    margin: 0 6px 12px;
  }
}

@media only screen and (max-width: 640px) {
  .profile_badge_list .profile_badge_item:nth-of-type(3n) {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_badge_list .profile_badge_item {
    width: calc( 20% - 12px );
    margin: 0 6px 12px;
  }
}

@media only screen and (max-width: 640px) {
  .profile_badge_list .profile_badge_item {
    width: calc( (100% - 40px) / 3 );
    text-align: center;
    margin: 0 20px 10px 0;
  }
}

.profile_common_block {
  margin: 0 0 100px;
}

@media only screen and (max-width: 960px) {
  .profile_common_block {
    margin: 0 0 50px;
  }
}

.profile_title {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0 0 10px;
  border-bottom: 2px #00ECBC solid;
  margin: 0 0 12px;

  &.mb24 {
    margin: 0 0 24px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_title {
    padding: 0 0 5px;
    margin: 0 0 8px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_title.mb24 {
    margin: 0 0 16px;
  }
}

.profile_edit_btn {
  font-size: 12px;
  color: #A8A8A8;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 1.5;
  text-decoration: none;

  img {
    width: 13px;
    display: inline-block;
    margin: 0 5px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .profile_edit_btn {
    font-size: 11px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_edit_btn img {
    width: 12px;
  }
}

.profile_table {
  width: 100%;
  color: #333;
  font-size: 14px;
  letter-spacing: 1.2px;
  line-height: 1.5;

  .profile_table_title {
    padding: 12px 0;
    width: 105px;
    vertical-align: top;
  }

  .profile_table_info {
    padding: 12px 0;
    font-weight: bold;
    vertical-align: top;
  }
}

@media only screen and (max-width: 960px) {
  .profile_table {
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_table .profile_table_title {
    padding: 8px 0;
    width: 85px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_table .profile_table_info {
    padding: 8px 0;
  }
}

.profile_dl {
  width: 100%;
  color: #333;
  font-size: 14px;
  letter-spacing: 1.2px;
  line-height: 1.5;

  .profile_dt {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 0 0 10px;
    border-bottom: 1px #D4D4D4 solid;
    margin: 0 0 10px;
    cursor: pointer;
    font-weight: bold;

    img {
      display: block;
      width: 14px;
    }

    &.active img {
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .profile_dd {
    display: none;
    font-weight: normal;
    font-size: 12px;
    line-height: 2;
    padding: 0 0 20px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_dl .profile_dt img {
    width: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_dl .profile_dd {
    font-size: 10px;
    padding: 0 0 12px;
  }
}

.profile_text {
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  letter-spacing: 1.2px;
}

@media only screen and (max-width: 960px) {
  .profile_text {
    font-size: 12px;
  }
}

.profile_company_none {
  font-size: 14px;
  color: #A8A8A8;
  line-height: 1.5;
  letter-spacing: 1.2px;
}

@media only screen and (max-width: 960px) {
  .profile_company_none {
    font-size: 12px;
  }
}

.profile_company_table {
  width: 100%;
  color: #333;
  font-size: 14px;
  letter-spacing: 1.2px;
  line-height: 1.5;

  .profile_company_title {
    font-weight: 900;
    padding: 0 0 12px;
  }

  .profile_company_text {
    padding: 12px 8px 12px 0;

    &:first-of-type {
      word-break: break-word;
    }
  }
}

@media only screen and (max-width: 960px) {
  .profile_company_table {
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_company_table .profile_company_title {
    padding: 0 0 8px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_company_table .profile_company_text {
    padding: 8px 8px 8px 0;
  }
}

.profile_company_delete_btn {
  display: block;
  width: 50px;
  height: 25px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  background: #007ADF;
  color: #fff;
  border: none;
  padding: 0;
  outline: none;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

@media only screen and (max-width: 960px) {
  .profile_company_delete_btn {
    font-size: 11px;
  }
}

.profile_unsubscribe_btn {
  display: block;
  width: 240px;
  height: 60px;
  line-height: 58px;
  text-decoration: none;
  text-align: center;
  border: 1px #007ADF solid;
  color: #007ADF;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.2px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &:hover {
    background: #007ADF;
    color: #fff;
  }
}

@media only screen and (max-width: 960px) {
  .profile_unsubscribe_btn {
    max-width: 240px;
    width: 100%;
    height: 50px;
    line-height: 48px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_unsubscribe_btn:hover {
    background: #fff;
    color: #007ADF;
  }
}

.profile_contact_btn {
  display: block;
  width: 240px;
  height: 60px;
  line-height: 60px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzY4YjhmYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -moz-linear-gradient(0deg, #007adf, #68b8fa);
  background: -webkit-linear-gradient(0deg, #007adf, #68b8fa);
  background: linear-gradient(90deg, #007adf, #68b8fa);
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.2px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

@media only screen and (max-width: 960px) {
  .profile_contact_btn {
    max-width: 240px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 12px;
  }
}

.profile_upload_btn {
  outline: none;
  border: none;
  display: block;
  width: 240px;
  height: 50px;
  line-height: 50px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background: #007ADF;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.2px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

@media only screen and (max-width: 960px) {
  .profile_upload_btn {
    max-width: 240px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
  }
}

/* edit */

.profile_edit_wrap {
  padding: 120px 0 140px;
}

@media only screen and (max-width: 960px) {
  .profile_edit_wrap {
    padding: 60px 0 70px;
  }
}

.profile_edit_table {
  width: 100%;
  margin: 0 auto 15px;

  &.w800 {
    max-width: 800px;
  }

  .profile_edit_title {
    width: 170px;
    text-align: right;
    vertical-align: top;
    font-size: 16px;
    color: #242424;
    line-height: 1.5;
    letter-spacing: 1.2px;
    font-weight: 500;
    padding: 5px 0 50px;

    span {
      font-size: 12px;
      background: #00ECBC;
      display: inline-block;
      padding: 2px 10px;
      color: #fff;
      margin: 0 0 0 10px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      border-radius: 4px;
    }
  }

  .profile_edit_info {
    padding: 0 0 50px 30px;

    .profile_dl {
      padding: 10px 0 0;
    }
  }
}

@media only screen and (max-width: 960px) {
  .profile_edit_table .profile_edit_title {
    font-size: 13px;
    padding: 5px 0 25px;
    width: 130px;
  }
}

@media only screen and (max-width: 640px) {
  .profile_edit_table .profile_edit_title {
    text-align: left;
    display: block;
    width: 100%;
    padding: 0 0 10px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_edit_table .profile_edit_title span {
    font-size: 10px;
  }
}

@media only screen and (max-width: 640px) {
  .profile_edit_table .profile_edit_title .sp_none {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  .profile_edit_table .profile_edit_info {
    padding: 0 0 25px 12px;
  }
}

@media only screen and (max-width: 640px) {
  .profile_edit_table .profile_edit_info {
    display: block;
    width: 100%;
    padding: 0 0 25px;
  }
}

@media only screen and (max-width: 960px) {
  .profile_edit_table .profile_edit_info .profile_dl {
    padding: 4px 0 0;
  }
}

.profile_edit_error_msg {
  display: none;
  font-size: 12px;
  color: red;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 5px 0 0;
}

.profile_edit_input {
  position: relative;
  display: block;
  width: 100%;
  height: 35px;
  outline: none;
  border: none;
  border-bottom: 1px #D4D4D4 solid;
  font-size: 14px;
  color: #333;
  letter-spacing: 1.2px;

  &.w100 {
    max-width: 100px;
  }

  &.w120 {
    max-width: 120px;
  }

  &.w180 {
    max-width: 180px;
    min-width: 155px;
  }

  &.w300 {
    max-width: 300px;
  }
}

.profile_edit_select {
  display: block;
  width: 100%;
  height: 35px;
  outline: none;
  border: none;
  border-bottom: 1px #D4D4D4 solid;
  font-size: 14px;
  color: #333;
  letter-spacing: 1.2px;
  background: url("/images/common/icon08.svg") right center no-repeat #fff;
  background-size: 10px;
  padding: 0 20px 0 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;

  &.w500 {
    max-width: 500px;
  }

  &.w200 {
    max-width: 200px;
  }

  &.w100 {
    max-width: 100px;
  }

  &.w80 {
    max-width: 80px;
  }
}

.profile_birth_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 14px;
  letter-spacing: 1.2px;
  line-height: 1.5;
  color: #333;

  .profile_edit_select {
    margin: 0 5px;
  }
}

.profile_zip_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 14px;
  letter-spacing: 1.2px;
  line-height: 1.5;
  color: #333;

  .profile_edit_input {
    margin: 0 5px;
  }
}

.profile_rank_block {
  padding: 8px 0 0;
}

.profile_edit_subtitle {
  font-size: 13px;
  color: #A8A8A8;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 1.5;
}

.profile_license_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.profile_license_delete {
  display: block;
  width: 20px;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  margin: 0 20px 0 0;

  img {
    display: block;
    width: 16px;
    margin: 0 auto;
  }
}

.profile_skill_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  .profile_edit_subtitle {
    min-width: 150px;
    margin: 0 15px 0 0;
  }
}

@media only screen and (max-width: 640px) {
  .profile_skill_block .profile_edit_subtitle {
    min-width: 100px;
    width: 100px;
    margin: 0 10px 0 0;
  }
}

.profile_company_block {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.profile_company_block_text {
  font-size: 14px;
  color: #242424;
  line-height: 1.8;
  letter-spacing: 1.2px;
  margin: 0 0 40px;
}

@media only screen and (max-width: 960px) {
  .profile_company_block_text {
    font-size: 13px;
    margin: 0 0 30px;
  }
}
