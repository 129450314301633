.react-select-debeers{
  width: 100%;
  max-width: 430px;
  .react-select__control {
    border-radius: 0;
    font-size: 14px;
    border-radius: 0;
    border: none;
    border: 1px solid #b8b8b8;
    box-shadow: none;
    min-height: 44px;
  }

  .react-select__control:hover {
    border: 1px solid #b8b8b8;
  }
  
  .react-select__single-value {
    line-height: 19px;
  }

  .react-select__indicators {
    width: 44px;
  }

  .react-select__indicator-separator {
    margin: 0;
  }
  
  .react-select__indicator {
    margin: 0 auto;
  }

  .react-select__menu {
    border-radius: 0;
    border: none;
  }
  .react-select__menu-list{
    padding: 0;
  }

  .react-select__option {
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.5rem;
    text-align: left;
    text-transform: none;
    font-size: 14px;
    display: block;
    padding: 1rem;
    border: 0.5px solid #b8b8b8;    
  }

  .react-select__option--is-selected {
    color: #fff;
    background: #0d1a29 !important;
  }

  .react-select__option--is-focused {
    background: #b8b8b8;
  }
}